import { NavLink, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import postscribe from "postscribe";
import Lightbox from "yet-another-react-lightbox";
import HeadTags from 'shared/HeadTags';
import ym from 'react-yandex-metrika';
import job from 'assets/images/contacts_job.jpg';

import "yet-another-react-lightbox/styles.css";

const bigImages = [
	{ src: require('assets/images/naugorka_1.jpg') },
	{ src: require('assets/images/naugorka_2.jpg') },
	{ src: require('assets/images/naugorka_3.jpg') },
	{ src: require('assets/images/naugorka_4.jpg') },
	{ src: require('assets/images/kromi_1.jpg') },
	{ src: require('assets/images/kromi_2.jpg') },
	{ src: require('assets/images/kromi_3.jpg') },
	{ src: require('assets/images/kromi_4.jpg') },
	{ src: require('assets/images/kromi_okt_1.jpg') },
	{ src: require('assets/images/kromi_okt_2.jpg') },
	{ src: require('assets/images/kromi_okt_3.jpg') },
	{ src: require('assets/images/kromi_okt_4.jpg') },
	{ src: require('assets/images/bolhov_1.jpg') },
	{ src: require('assets/images/bolhov_2.jpg') },
	{ src: require('assets/images/bolhov_3.jpg') },
	{ src: require('assets/images/bolhov_4.jpg') },
];

const ContactsPage = () => {
	const mapRef = useRef(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (mapRef) {
			postscribe(
				mapRef.current,
				`<script src='https://widgets.2gis.com/js/DGWidgetLoader.js'></script>
				<script>
				if (!window.DGWidgetRendered) {
					new DGWidgetLoader({
						"height": 400,
						"borderColor": "#a3a3a3",
						"pos": {
							"lat": 52.997429890543785,
							"lon": 36.03755950927735,
							"zoom": 12
						},
						"opt": {
							"city": "orel"
						},
						"org": [{
							"id": "70000001031121067"
						}]
					});
					window.DGWidgetRendered = true;
				};
				</script>`
			);
			return () => {
				window['DGWidgetRendered'] = false;
			}
	   }
	}, [mapRef]);

	useEffect(() => {
		ym('hit', '/kontaktyi');
	}, []);
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}, [navigate]);

	const [galleryIndex, setGalleryIndex] = useState(-1);
	const handleGalleryClick = (index: number) => setGalleryIndex(index);
  
	return (
		<div className="container mr-tp-20 mr-bt-20">
			<HeadTags
				title='Контакты - магазин "Универсал" в Орле'
				metaDescription='На сайте вы найдете адреса и контактную информацию всех магазинов в Орле и Орловской области группы компаний "Универсал".'
			/>
			<ul className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
				<li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
					<NavLink to="/" itemProp="item">Главная</NavLink>
					<meta itemProp="position" content="1" />
				</li>
				<li className="breadcrumb-item active" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
					<span itemProp="item">Контакты</span> 
					<meta itemProp="position" content="2" />
				</li>
			</ul>
			<h1 className="mr-tp-20">Контакты</h1>
			<div className="contacts_job">
				<img className="contacts_job__img" src={job} alt="Открыта вакансия" title="Открыта вакансия продавец-консультант в г. Орел"/>
				<div className="contacts_job__text">
					<p className="contacts_job__text_title">Открыта вакансия:<br/> <strong>продавец-консультант в г. Орел</strong></p>
					<p className="contacts_job__text_tel">
						<span>Подробности по тел: </span>
						<a href="tel:+74862510038">+7(4862) 51-00-38</a>
					</p>
				</div>
			</div>
			{/* <p style={{
				fontSize: '20px',
				textAlign: 'center',
				padding: '10px 0 30px',
				color: 'red'
			}}><b>ВНИМАНИЕ! 12 июня все магазины "Универсал" работают до 17.00</b></p> */}
			<div className="contacts_block" id="orel">
				<div className="contacts_block__text" itemScope itemType="http://schema.org/LocalBusiness ">
					<p><b itemProp="name">Торговый центр «Универсал»</b></p>
					<p itemProp="address" itemScope itemType="http://schema.org/PostalAddress"><b><span itemProp="addressLocality">г. Орел</span>, <span itemProp="streetAddress">Наугорское шоссе, д. 19, стр. 1</span></b></p>
					<p>Режим работы:<br/> <b>Пн-сб: с 8.30 до 19.00 без перерыва</b><br/><b>Вс: с 8.30 до 18.00 без перерыва</b></p>					
					<p>Телефон: <a href="tel:84862510038" itemProp="telephone" rel="nofollow noopener noreferrer">+7 (4862) 51-00-38</a></p>
					<div className="contacts_block__images" itemScope itemType="http://schema.org/ImageObject">
						<img alt="Торговый центр «Универсал» фото 1" itemProp="contentUrl" src={require('assets/images/naugorka_1-.jpg')} title="На фото - Торговый центр «Универсал»" onClick={() => handleGalleryClick(0)} />
						<img alt="Торговый центр «Универсал» фото 2" itemProp="contentUrl" src={require('assets/images/naugorka_2-.jpg')} title="На фото - Торговый центр «Универсал»" onClick={() => handleGalleryClick(1)} />
						<img alt="Торговый центр «Универсал» фото 3" itemProp="contentUrl" src={require('assets/images/naugorka_3-.jpg')} title="На фото - Торговый центр «Универсал»" onClick={() => handleGalleryClick(2)} />
						<img alt="Торговый центр «Универсал» фото 4" itemProp="contentUrl" src={require('assets/images/naugorka_4-.jpg')} title="На фото - Торговый центр «Универсал»" onClick={() => handleGalleryClick(3)} />
					</div>
					<meta itemProp="openingHours" content="Mo-Su 08:30−19:00" />
				</div>
				<div className="dg-widge contacts_block__map" ref={mapRef}>
					<a className="dg-widget-link" href="http://2gis.ru/orel/firm/70000001031121067/center/36.03755950927735,52.997429890543785/zoom/12?utm_medium=widget-source&amp;utm_campaign=firmsonmap&amp;utm_source=bigMap">Посмотреть на карте Орла</a>
					<div className="dg-widget-link"><a href="http://2gis.ru/orel/firm/700000010311...rce=photos">Фотографии компании</a></div>
					<div className="dg-widget-link"><a href="http://2gis.ru/orel/center/36.04191,52.986758/zoom/12/routeTab/rsType/bus/to/36.04191,52.986758╎УНИВЕРСАЛ, магазин?utm_medium=widget-source&amp;utm_campaign=firmsonmap&amp;utm_source=route">Найти проезд до УНИВЕРСАЛ, магазин</a></div>
				</div>
			</div>
			<div className="contacts_block" id="kromy">
				<div className="contacts_block__text" itemScope itemType="http://schema.org/LocalBusiness ">
					<p><b itemProp="name">Оптово-розничная база ремонтно-строительных материалов</b> (Евровагонка, блок-хаус, имитация бруса, погонаж хвойных пород, гипсокартон, металло-профиль, OSB):</p>
					<p itemProp="address" itemScope itemType="http://schema.org/PostalAddress"><b>На въезде в <span itemProp="addressLocality">Кромы, дер.Черкасская,</span><br />
							<span itemProp="streetAddress">пер. Маслозаводской, д.1а</span></b> <a href="tel:+74862510038" itemProp="telephone" style={{display:'none'}}>84862510038</a></p>
					<p>Режим работы: <b>с 10.00 до 17.00 без перерыва и выходных</b></p>
					<div className="contacts_block__images" itemScope itemType="http://schema.org/ImageObject">
						<img alt="Оптово-розничная база ремонтно-строительных материалов фото 1" itemProp="contentUrl" src={require('assets/images/kromi_1-.jpg')} onClick={() => handleGalleryClick(4)}  title="На фото - Оптово-розничная база ремонтно-строительных материалов" />
						<img alt="Оптово-розничная база ремонтно-строительных материалов фото 2" itemProp="contentUrl" src={require('assets/images/kromi_2-.jpg')} onClick={() => handleGalleryClick(5)}  title="На фото - Оптово-розничная база ремонтно-строительных материалов" />
						<img alt="Оптово-розничная база ремонтно-строительных материалов фото 3" itemProp="contentUrl" src={require('assets/images/kromi_3-.jpg')} onClick={() => handleGalleryClick(6)}  title="На фото - Оптово-розничная база ремонтно-строительных материалов" />
						<img alt="Оптово-розничная база ремонтно-строительных материалов фото 4" itemProp="contentUrl" src={require('assets/images/kromi_4-.jpg')} onClick={() => handleGalleryClick(7)}  title="На фото - Оптово-розничная база ремонтно-строительных материалов" />
					</div>
					<meta itemProp="openingHours" content="Mo-Su 10:00−17:00" />
				</div>
				<div className="contacts_block__map">
					<iframe title="Кромы, дер.Черкасская" frameBorder="0" height="310" src="https://yandex.ru/map-widget/v1/?um=constructor%3A7f9aad891f264f358253e9cb23a03c0b8ce31459e280f53ed1eaf09a7bbd3ef6&amp;source=constructor" width="100%"></iframe>
				</div>
			</div>
			<div className="contacts_block">
				<div className="contacts_block__text" itemScope itemType="http://schema.org/LocalBusiness ">
					<p><b itemProp="name">Строительно-хозяйственный магазин</b></p>
					<p itemProp="address" itemScope itemType="http://schema.org/PostalAddress"><b><span itemProp="addressLocality">г. Кромы</span> <span itemProp="streetAddress">ул. 25 Октября, д.29</span></b></p>
					<p>Режим работы: <b>с 09.00 до 18.00 без перерыва и выходных.</b></p>					
					<p>Телефон: <a href="tel:+74864320534" itemProp="telephone" rel="nofollow noopener noreferrer">+7 (48643) 2-05-34</a></p>
					<div className="contacts_block__images" itemScope itemType="http://schema.org/ImageObject">
						<img alt="Строительно-хозяйственный магазин фото 1" itemProp="contentUrl" src={require('assets/images/kromi_okt_1-.jpg')} onClick={() => handleGalleryClick(8)} title="На фото - Строительно-хозяйственный магазин" /> 
						<img alt="Строительно-хозяйственный магазин фото 2" itemProp="contentUrl" src={require('assets/images/kromi_okt_2-.jpg')} onClick={() => handleGalleryClick(9)} title="На фото - Строительно-хозяйственный магазин" /> 
						<img alt="Строительно-хозяйственный магазин фото 3" itemProp="contentUrl" src={require('assets/images/kromi_okt_3-.jpg')} onClick={() => handleGalleryClick(10)} title="На фото - Строительно-хозяйственный магазин" />
						<img alt="Строительно-хозяйственный магазин фото 4" itemProp="contentUrl" src={require('assets/images/kromi_okt_4-.jpg')} onClick={() => handleGalleryClick(11)} title="На фото - Строительно-хозяйственный магазин" />
					</div>
					<meta itemProp="openingHours" content="Mo-Su 09:00−18:00" />
				</div>
				<div className="contacts_block__map">
					<iframe title="Кромы, ул. 25 Октября, д.29" height="310" src="https://yandex.ru/map-widget/v1/?um=constructor%3A20e13e074d6dc0cf34d8424d6964b44fa2fe40dfae959a9b3f60d0d8a51ac3ee&amp;source=constructor" width="100%"></iframe>
				</div>
			</div>
			<div className="contacts_block" id="bolhov">
				<div className="contacts_block__text" itemScope itemType="http://schema.org/LocalBusiness ">
					<p><b itemProp="name">Строительно-хозяйственный магазин «Мастер М» – </b></p>
					<p itemProp="address" itemScope itemType="http://schema.org/PostalAddress"><b><span itemProp="addressLocality">г. Болхов</span> <span itemProp="streetAddress">ул. Тургенева, д. 78 </span><br />
							(цокольное помещение под ФиксПрайсом).</b></p>
					<p>Режим работы: <b>с 09.00 до 18.00 без перерыва и выходных.</b></p>					
					<p>Телефон: <a href="tel:+79536151653" itemProp="telephone" rel="nofollow noopener noreferrer">+7-953-615-16-53</a></p>
					<div className="contacts_block__images" itemScope itemType="http://schema.org/ImageObject">
						<img alt="Строительно-хозяйственный магазин «Мастер М» фото 1" itemProp="contentUrl" src={require('assets/images/bolhov_1-.jpg')} onClick={() => handleGalleryClick(12)} title="На фото - Строительно-хозяйственный магазин «Мастер М»" />
						<img alt="Строительно-хозяйственный магазин «Мастер М» фото 2" itemProp="contentUrl" src={require('assets/images/bolhov_2-.jpg')} onClick={() => handleGalleryClick(13)} title="На фото - Строительно-хозяйственный магазин «Мастер М»" />
						<img alt="Строительно-хозяйственный магазин «Мастер М» фото 3" itemProp="contentUrl" src={require('assets/images/bolhov_3-.jpg')} onClick={() => handleGalleryClick(14)} title="На фото - Строительно-хозяйственный магазин «Мастер М»" />
						<img alt="Строительно-хозяйственный магазин «Мастер М» фото 4" itemProp="contentUrl" src={require('assets/images/bolhov_4-.jpg')} onClick={() => handleGalleryClick(15)} title="На фото - Строительно-хозяйственный магазин «Мастер М»" />
					</div>
					<meta itemProp="openingHours" content="Mo-Su 09:00−18:00" />
				</div>
				<div className="contacts_block__map">
					<iframe title="г. Болхов, ул. Тургенева, д. 78" frameBorder="0" height="310" src="https://yandex.ru/map-widget/v1/?um=constructor%3A6f3ecddfa35e4af1b2a9c6ccb83c0bddca454ce8946a7cf217dd059337c9556d&amp;source=constructor" width="100%"></iframe>
				</div>
			</div>
			<Lightbox
				slides={bigImages}
				open={galleryIndex >= 0}
				index={galleryIndex}
				close={() => setGalleryIndex(-1)}
			/>
		</div>
	)
}
export default ContactsPage;