import React, {useEffect} from 'react';
import { useGetCategoryQuery } from 'services/api/api';
import LoadingBlock from 'shared/LoadingBlock';
import { NavLink, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import HeadTags from "shared/HeadTags";
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import topImg from 'assets/images/main_top_text.jpg';
import btmImg from 'assets/images/main_btm_text.jpg';
import slide1 from 'assets/images/slide_1.jpg';
import slide1_mid from 'assets/images/slide_1_1000.jpg';
import slide1_xs from 'assets/images/slide_1-.jpg';
import NoImage from 'shared/NoImage';
import ym from 'react-yandex-metrika';

import billetImg from 'assets/images/billet_012025.jpg';
import billetImgMob from 'assets/images/billet_012025_sm.jpg';

// import nyImg from 'assets/images/ny_2.1.jpg';
// import nyImgSm from 'assets/images/ny_2.2.jpg';

const HomePage = () => {
  const { data, isLoading } = useGetCategoryQuery();
  const navigate = useNavigate();

  useEffect(() => {
    ym('hit', '/');
  }, []);
  useEffect(() => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}, [navigate]);

  const mainCatalog = data 
    ? data.categories.map(el => {
        return ( 
          <SwiperSlide key={el.id}>
            <div className="item" itemScope itemType="http://schema.org/ImageObject">
              <NavLink className="catalog-item" to={`/catalog/${el.slug}`}>
                <span className="catalog-item-img">
                  {el.image
                    ? <img alt={`${el.name} - фото`} src={el.image} title={el.name} itemProp="contentUrl"/>
                    : <NoImage />
                  }
                </span>
                <span className="catalog-item-capt" itemProp="name">{el.name}</span>
              </NavLink>
            </div>
          </SwiperSlide>
        )
      })
    : <></>;

  return (
    <>
      <HeadTags
        title='Строительный магазин в Орле - ТЦ Универсал '
        metaDescription='Магазин недорогих строительных и отделочных материалов в Орле - ТЦ "Универсал". Все для ремонта и отделки в одном месте. Доставка по городу и области.'
      />
      <div className="header-main item">
        <picture> 
          <source media="(max-width: 520px)" srcSet={slide1_xs} /> 
          <source media="(max-width: 980px)" srcSet={slide1_mid} /> 
          <img alt="Магазин строительных материалов" src={slide1} title="Магазин строительных материалов" />
        </picture>
        <div className="container">
          <div className="header-title">
              <h1>Магазин строительных материалов</h1>
          </div>
        </div>
      </div>
      {/* <div style={{textAlign: 'center', padding: '30px 10px 10px'}}>
        <picture> 
          <source media="(max-width: 600px)" srcSet={nyImgSm} /> 
          <img alt="С Новым Годом!" src={nyImg} title="С Новым Годом!" />
        </picture>
      </div> */}
      <div className="green_line">
        <div className="container main_text" itemScope itemType="http://schema.org/ImageObject">
            <div className="main_text__img">
                <img src={topImg} alt="Строительные материалы" title="Строительные материалы в Орле" itemProp="contentUrl" />
            </div>
            <div className="main_text__text" itemProp="description">
                <p>Компания "Универсал" создана в 2007 году. Сфера нашей работы — розничные и оптовые продажи строительных и отделочных материалов по региону. Сегодня наши магазины есть в Орле, Болхове, Кромах.</p>
                <p>Для покупок из дома или офиса мы открыли Интернет-магазин. Вы оформляете заказ, мы —&nbsp; привозим товар. В каталоге — сотни предложений от российских и иностранных производителей.</p>
            </div>
        </div>
      </div>
      <LoadingBlock isLoading={isLoading}>
        <div className="container text-center main_catalog">
          <h2>Все для ремонта и отделки в одном месте</h2>
          <div className="main_catalog__slider">
            <Swiper
              className='list'
              spaceBetween={20}
              modules={[Autoplay, Navigation]}
              navigation={{
                nextEl: '#next',
                prevEl: '#prev',
              }}
              autoplay={{
                disableOnInteraction: false,
              }}
              breakpoints={{
                400: {
                  slidesPerView: 2,
                },
                640: {
                  slidesPerView: 3,
                },
                900: {
                  slidesPerView: 4,
                },
              }}
            >
              {mainCatalog}
            </Swiper>
            <div className="swiper-button-prev" id="prev"></div>
            <div className="swiper-button-next" id="next"></div>
          </div>
        </div>
      </LoadingBlock>

      <div className="billet-block">
          <a href="/catalog/santexnika/inzhenernaya-santehnika-vodootvedenie-vodosnabzhenie/product/tabletirovannaya-sol">
            <picture>
								<source media="(max-width: 461px)" srcSet={billetImgMob} />
								<img src={billetImg} alt="Новинка! Соль таблетированная для водоподготовки" title="Новинка! Соль таблетированная для водоподготовки" />
							</picture>
          </a>
      </div>

      <div className="preffs">
        <div className="container">
          <h3>Шесть поводов выбрать нас</h3>
          <ul className="preffs_list">
            <li className="pref_1"><span>Качественная<br/> продукция по<br/> доступным ценам</span></li>
            <li className="pref_2"><a href="/catalog"><span>Большая номенклатура товаров для ремонта и строительства</span></a></li>
            <li className="pref_3"><a data-fancybox="" data-options="{&quot;touch&quot; : false}" href="#callback"><span>Покупка за несколько минут: достаточно позвонить или оставить онлайн-заявку.</span></a></li>
            <li className="pref_4"><a href="/dostavka"><span>Доставка в день заказа.</span></a></li>
            <li className="pref_5"><span>Удобная форма оплаты: наличными, банковской картой, для организаций — через расчетный счет.</span></li>
            <li className="pref_6"><a href="/akczii"><span>Бонусы для<br/> постоянных покупателей.</span></a></li>
          </ul>
        </div>
      </div>
      <div className="green_line green_line--bottom">
        <div className="container main_text" itemScope itemType="http://schema.org/ImageObject">
          <div className="main_text__img">
            <img alt="гипсокартон, сухие смеси" itemProp="contentUrl" src={btmImg} title="Мы продаем пиломатериалы, гипсокартон, сухие смеси и т.д"/>
          </div>
          <div className="main_text__text" itemProp="description">
            <p>Наличие своих складов — гарантия выбора и бесперебойного снабжения торговых площадок. Мы продаем <a href="/catalog/stroymaterialy/pilomaterialyi">пиломатериалы,</a> <a href="/catalog/stroymaterialy/gipsokarton-i-komplektuyuschie">гипсокартон,</a> <a href="/catalog/stroymaterialy/cement-suhie-smesi-shpatlevki-i-gruntovki">сухие смеси,</a> <a href="/catalog/instrumentyi">инструменты,</a> <a href="/catalog/krepyozh">элементы крепежа,</a> <a href="/catalog/santexnika">сантехнику,</a> <a href="/catalog/otoplenie-ventilyaciya">оборудование для систем вентиляции и отопления,&nbsp;</a> <a href="/catalog/hoztovary">хозтовары,</a> <a href="/catalog/domashniy-tekstil">домашний текстиль и т.д.</a> Когда все собрано в одном месте, покупать удобно.</p>
            <p className="big">Звоните и приходите.<br/>Будем рады видеть вас среди наших клиентов.</p>
          </div>
        </div>
      </div>
    </>
  )
};
export default HomePage;