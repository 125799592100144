import { useState, useEffect } from 'react';
import { useGetCookie } from 'app/hooks';
import promoImg from 'assets/images/promo_012025.png';
import promoImgMob from 'assets/images/promo_012025_mob.png';

const PromoModal = () => {
	const [isShow, setIsShow] = useState<boolean>(false);
	const was = useGetCookie("popup");
	useEffect(() => {
		if (was !== "no") { 
			setTimeout(() => {
				setIsShow(true);
				document.cookie = "popup=no; path=/";
			}, 10000);
		}
	}, []);
	return (
		<>
			{isShow &&
				<div className='modal promo_popup' onClick={() => setIsShow(false)}>
					<div className="modal__inner" onClick={e => e.stopPropagation()}>
						<div>
							<picture>
								<source media="(max-width: 768px)" srcSet={promoImgMob} />
								<img src={promoImg} alt="СКИДКа 10% НА ЭЛЕКТРИКУ И ЛЮСТРЫ" title="СКИДКа 10% НА ЭЛЕКТРИКУ И ЛЮСТРЫ" />
							</picture>
						</div>
						<button type="button" className="modal__close" title="Close" onClick={() => setIsShow(false)}>
							<svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"></path></svg>
						</button>
					</div>
				</div>
			}
		</>
	);
};
export default PromoModal;