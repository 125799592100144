import { Link, useNavigate } from "react-router-dom";
import HeadTags from "shared/HeadTags";
import { useEffect } from "react";

import img_sale from "assets/images/promo_012025_mob.png";
import img_card from "assets/images/diskont15.12.jpg";
import img_kraska from "assets/images/slidla_kraska.jpg";
import img_emal from "assets/images/slidla_emal.jpg";
import img_provoda from "assets/images/slidki_provoda.jpg";
import img_pvhpaneli from "assets/images/skidki_pvhpaneli.jpg";
import ym from "react-yandex-metrika";

const PromoPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    ym("hit", "/akczii");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [navigate]);

  return (
    <div className="container mr-tp-20">
      <HeadTags
        title='Акции и распродажа стройматериалов в Орле - магазин "Универсал"'
        metaDescription="Скидки и распродажа стройматериалов в Орле. Сезонные и специальные  предложения от поставщиков."
      />
      <ul
        className="breadcrumbs"
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        <li
          className="breadcrumb-item"
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <Link to="/" itemProp="item">
            Главная
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        <li
          className="breadcrumb-item active"
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
        >
          <span itemProp="item">Акции</span>
          <meta itemProp="position" content="2" />
        </li>
      </ul>
      <h1 className="mr-tp-20">Акции</h1>
      <p>&nbsp;</p>

      <div className="sale_item">
        <div className="sale_item__img">
          <img
            alt="СКИДКА 10% НА ЭЛЕКТРИКУ И ЛЮСТРЫ"
            className="sale-img"
            src={img_sale}
            title="СКИДКА 10% НА ЭЛЕКТРИКУ И ЛЮСТРЫ"
          />
        </div>
        <div className="sale_item__text">
          <p className="sale-capt">
            <span>
              СКИДКА <b>10%</b> 
            </span>
          </p>
          <div className="sale-desc">
            <p>НА ЭЛЕКТРИКУ И ЛЮСТРЫ</p>
          </div>
        </div>
      </div>
      <div className="sale_item">
        <div className="sale_item__img">
          <img
            alt="Программа лояльности! Скидка 5% по дисконтной карте. - фото"
            className="sale-img"
            src={img_card}
            title="Программа лояльности! Скидка 5% по дисконтной карте."
          />
        </div>
        <div className="sale_item__text">
          <p className="sale-capt">
            <span>
              Программа лояльности! Скидка <b>5%</b> по дисконтной карте.
            </span>
          </p>
          <div className="sale-desc">
            <p>Выдается бесплатно при покупке от 5000 руб.</p>
          </div>
        </div>
      </div>
      <div className="sale_item">
        <div className="sale_item__img">
          <img
            alt="Скидка 10% на краску"
            className="sale-img"
            src={img_kraska}
            title="Скидка 10% на краску ПФ-115 Ярославский колорит, Норма, Хозяин, Красотка"
          />
        </div>
        <div className="sale_item__text">
          <p className="sale-capt">
            <span>Скидка</span> <b>до 30%</b> на краску
          </p>
          <div className="sale-desc">
            <p>ПФ-115 Ярославский колорит, Норма, Хозяин, Красотка</p>
          </div>
        </div>
      </div>
      <div className="sale_item">
        <div className="sale_item__img">
          <img
            alt="Скидка 10% на грунт-эмаль"
            className="sale-img"
            src={img_emal}
            title="Скидка 10% на грунт-эмаль Ярославский колорит"
          />
        </div>
        <div className="sale_item__text">
          <p className="sale-capt">
            <span>Скидка</span> <b>до 30%</b> на грунт-эмаль
          </p>
          <div className="sale-desc">
            <p>Ярославский колорит</p>
          </div>
        </div>
      </div>
      <div className="sale_item">
        <div className="sale_item__img">
          <img
            alt="Скидка 15%"
            className="sale-img"
            src={img_provoda}
            title="Скидка 15% на остатки проводов"
          />
        </div>
        <div className="sale_item__text">
          <p className="sale-capt">
            <span>Скидка</span> <b>15%</b>
          </p>
          <div className="sale-desc">
            <p>на остатки проводов</p>
          </div>
        </div>
      </div>
      <div className="sale_item">
        <div className="sale_item__img">
          <img
            alt="Скидка 10% на ПВХ-панели"
            className="sale-img"
            src={img_pvhpaneli}
            title="Скидка 10% на ПВХ-панели (отдельные позиции)"
          />
        </div>
        <div className="sale_item__text">
          <p className="sale-capt">
            <span>Скидка</span> <b>10%</b> на ПВХ-панели
          </p>
          <div className="sale-desc">
            <p>(отдельные позиции)</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PromoPage;
